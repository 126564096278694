export const environment: any = {
    version: require('../../package.json').version,
    VERSION: require('../../package.json').version,
    API_ENDPOINT: 'https://md1-api.elements-eng.cloud:19443/api/v1',
    API_URL: 'https://md1-api.elements-eng.cloud:19443/api/v1/datastructures',
    API_STATIC_FILE_UPLOAD: 'https://eng-upload.q9elements.com:11443/api',
    APPNAME: 'HD-APP',
    ANALYSIS_API: 'https://dev-gateway.q9elements.com/automation-us-dependency-analysis/api/v1/dependency-analysis',
    CHANGELOG_APP_URL: 'https://md1-changelogs.elements-eng.cloud',
    CLOUD_NAME: 'elements',
    CLOUD_TYPE: 'public',
    DATA_STRUCTURE_URL: 'https://md1-datatable.elements-eng.cloud',
    DE_APP_URL: 'https://md1-diagram.elements-eng.cloud',
    DE_APP: 'https://md1-diagram.elements-eng.cloud',
    DIAGRAM_URL: 'https://md1-diagram.elements-eng.cloud',
    DOMAIN: 'elements-eng.cloud',
    ENTERPRISE_URL: 'https://md1-corp.elements-eng.cloud',
    ENV: 'md1',
    GDPR_SUPPORT_LINK: 'https://support.elements.cloud/en/articles/1221094-enable-data-privacy-gdpr-compliance-for-a-ref-model',
    HELP_LINK: 'https://support.elements.cloud/',
    IMPORT_MAP_API: 'https://dev-gateway.q9elements.com/md1-map-import/api/v1',
    IMPORTS_API: 'https://dev-gateway.q9elements.com/md1-import/api/v1',
    INTERCOM_APP_ID: 'sfi4u1en',
    INTERCOM_URL: 'string',
    MAIN_APP_URL: 'https://md1.elements-eng.cloud',
    MAIN_APP: 'https://md1.elements-eng.cloud',
    MAP_MANAGEMENT_APP: 'https://md1-mapmgt.elements-eng.cloud',
    NO_IMAGE_URL: '../../assets/images/no_image.png',
    NOUI: false,
    PARTNER_APP: 'https://md1-partnermgt.elements-eng.cloud',
    PREVIEW_HOLDER: '/assets/images/map_placeholder.png',
    production: true,
    PX_KEY: 'AP-3EN4L67VSTXG-2-3',
    RAVEN_DSN: 'https://eb6892047d9b42638a7563ddb26386ea@sentry.io/1233470',
    RAVEN_ID: '9e76fe14aaba4a31846fe607572f9eb1',
    REF_MODEL_APP: 'https://md1-refmodel.elements-eng.cloud',
    RELEASE_URL: 'https://md1-releasemgt.elements-eng.cloud',
    REMOTE_UNLOCK_TTL: 3600000,
    REPORTS_API_ENDPOINT: 'https://dev-gateway.q9elements.com/md1-report/api/v1',
    REPORTS_API: 'https://dev-gateway.q9elements.com/md1-report/api/v1', 
    REPORTS_APP: 'https://md1-report.elements-eng.cloud',
    SALESFORCE_CLIENT_ID: '3MVG9Rd3qC6oMalVszZtK2Usdv8tLz5qkPZz_KEFbCyXbZHiOue2YK1qV7T9jkb9fu7.Tp7JvNn1Xsz4DFKfu',
    SALESFORCE_REDIRECT_URL: 'https://md1.elements-eng.cloud/auth/callback',
    SALESFORCE_URL_TEST: 'https://test.salesforce.com/services/oauth2/authorize',
    SALESFORCE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SECURE_COOKIES: true,
    SYS_ADMIN_APP: 'https://md1-sysadmin.elements-eng.cloud',
    TEAM_APP: 'https://md1-spacemgt.elements-eng.cloud',
    TOASTR_TIMEOUT: 5000,
    UNLOCK_TTL: 180000,
    fbConfig: {
        apiKey:'AIzaSyDJ_50WYS4f-qi0bosHNAM050RPysGEz8Y',
        authDomain:'elements-md1-dntk.firebaseapp.com',
        databaseURL: 'https://elements-md1-dntk-default-rtdb.firebaseio.com',
        projectId: 'elements-md1-dntk',
        appId: '1:373319252103:web:5e40d7d971a08f6e2fe022'
    }
}; 
